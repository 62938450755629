.cadet-staff-container {
    max-width: 1200px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.staff-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: gold solid 2px;
}

.staff-section h2 {
    color: #ffd700;
    font-size: 2rem;
    margin: 10px 0;
}

.staff-section-content {
    width: 100dvw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
    gap: 20px;
    overflow-y: auto;
}

.staff-card {
    margin-bottom: 30px;
}

.large-cards .staff-card {
    width: 275px;
}

.medium-cards .staff-card {
    width: 250px;
}

.small-cards .staff-card {
    width: 225px;
}

p {
    font-size: 1.3rem;
    color: #ecf0f1;
    padding: 1px;
    margin: 1px 1px 5px;
}

.staff-image {
    width: 100%;
    height: auto;
    margin: 10px auto;
    border-radius: 10px;
    object-fit: cover;
}

.staff-card {
    position: relative;
    text-align: center;
}

.staff-title {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #333;
}

.officer .staff-info p,
.officer .staff-title {
    color: gold;
}

.enlisted .staff-info p,
.enlisted .staff-title {
    color: silver;
}

.officer .staff-image {
    box-shadow: 0 0 10px 0 gold;
}

.enlisted .staff-image {
    box-shadow: 0 0 10px 0 silver;
}

@media (max-width: 575px) {
    .large-cards .staff-card, .medium-cards .staff-card, .small-cards .staff-card {
        width: 300px;
    }
}
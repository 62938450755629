/* Header container styles */
.header-container {
    color: #fff;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #00264d;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    width: 100%;
}

/* Header content layout */
.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 1200px;
    min-height: 100px;
}

/* Logo container styles */
.logo-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.logo-container.left {
    left: 2rem;
}

.logo-container.right {
    right: 2rem;
}

.logo {
    height: 80px;
}

/* Title and nav container */
.title-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.title {
    font-size: 2rem;
    margin: 0.5rem 0;
    color: gold;
}

nav {
    flex-grow: 1;
    margin-top: 10px;
}

/* Navigation menu */
.header-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.header-menu li {
    position: relative;
}

.header-menu a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    padding: 8px 20px;
    transition: color 0.3s, background-color 0.3s, transform 0.3s ease;
    border-radius: 5px;
    background-color: #1e3c72;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.header-menu a:hover {
    background-color: #2a5298;
    color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header-menu a.active {
    background-color: #ffd700;
    color: #1e3c72;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
}

/* Cadet resources styles */
.cadet-resources {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1e3c72;
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-width: 160px;
}

.dropdown-menu-item button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    transition: color 0.3s, background-color 0.3s;
}

.dropdown-menu-item button:hover {
    color: #ffd700;
    background-color: #003366;
    border-radius: 4px;
}

.dropdown-menu-item button.active {
    background-color: #ffd700;
    color: #1e3c72;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
}

.dropdown-menu-item {
    border-top: 1px solid #2a5298;
}

.dropdown-menu-item:first-child {
    border-top: none;
}

/* Dropdown for mobile devices */
.dropdown {
    display: none;
}

@media (max-width: 575px) {
    .title, h1 {
        font-size: 1.5rem;
        width: 90dvw;
        margin: 0;
    }

    /* Hide the main header menu and use the dropdown instead */
    .header-menu {
        display: none;
    }

    .dropdown {
        display: block;
        margin: 10px 0;
        text-align: center;
    }

    .dropdown select {
        padding: 10px;
        font-size: 1.1rem;
        color: #ffd700;
        background-color: #1a5f7a;
        border-radius: 5px;
        border: none;
    }

    .dropdown select option[value="/ribbon-checker"] {
        display: none; /* Hide the Ribbon Checker option on mobile */
    }

    .logo-container {
        display: none;
    }

    /* Reduce padding to make better use of space */
    .header-container {
        padding: 5px 0;
    }

    /* Adjust the title-nav-container to better align content */
    .title-nav-container {
        margin-top: 5px;
    }

    /* Increase the header's z-index to ensure it stays on top */
    .header-container {
        z-index: 1000;
    }
}

.home-container {
    max-width: 1200px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: #16222a;
    color: #ecf0f1;
    padding: 20px 20px 0 20px;
}

.home-intro-section img {
    max-width: 100%;
}

.home-intro-section {
    text-align: center;
    margin-bottom: 40px;
}

.home-intro-section h1 {
    font-size: 2.5rem;
    color: #ffd700; /* Gold color for headings */
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    animation: fadeIn 1.5s ease-in-out;
}

.home-intro-section p {
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.unit-image {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    animation: zoomIn 1s ease-in-out;
}

.highlight-section {
    margin-bottom: 40px;
    max-width: 700px;
    text-align: center;
    padding: 20px;
    background-color: #1a2a3a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 1s ease-in-out;
}

.highlight-section h2 {
    font-size: 2rem;
    color: #ffd700; /* Gold color for subheadings */
    margin-top: 20px;
    margin-bottom: 10px;
}

.highlight-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.highlight-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.highlight-section ul {
    list-style-type: none;
    padding: 0;
    font-size: 1.2rem;
}

.highlight-section ul li {
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    .home-intro-section h1 {
        font-size: 1.5rem;
    }

    .home-intro-section img {
        max-width: 90dvw;
    }

    .home-intro-section {
        margin-bottom: 0;
    }

    .highlight-section {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

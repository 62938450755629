.about-container {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: #16222a;
    color: #ecf0f1;
    padding-top: 20px;
    max-width: 1000px;
}

.about-intro-section {
    text-align: center;
    margin-bottom: 40px;
}

.about-intro-section img {
    max-width: 150px;
    margin-bottom: 20px;
    animation: zoomIn 1s ease-in-out;
}

.about-intro-section h1 {
    font-size: 2.5rem;
    color: #ffd700;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    animation: fadeIn 1.5s ease-in-out;
    max-width: 700px;
}

.about-intro-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.content-section, .contact-section {
    margin-bottom: 40px;
    max-width: 700px;
    text-align: center;
    padding: 20px;
    background-color: #1a2a3a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 1s ease-in-out;
}

.content-section h2 {
    font-size: 2rem;
    color: #ffd700; /* Gold color for subheadings */
    margin-top: 20px;
    margin-bottom: 10px;
}

.content-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.contact-section h2 {
    font-size: 2rem;
    color: #ffd700; /* Gold color for subheadings */
    margin-top: 20px;
    margin-bottom: 10px;
}

.contact-section ul {
    list-style-type: none;
    padding: 0;
}

.contact-section ul li {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.contact-section ul li a {
    color: #007bff;
    text-decoration: none;
}

.contact-section ul li a:hover {
    text-decoration: underline;
}

@media (max-width: 575px) {
    .about-intro-section {
        margin-bottom: 0;
    }
    .about-intro-section h1 {
        font-size: 1.5rem;
    }
    .about-intro-section img {
        max-width: 125px;
        margin-bottom: 0;
    }
    .content-section, .contact-section {
        max-width: 85dvw;
        margin-bottom: 20px;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* General reset and base styles */
body, html {
    margin: 0;
    padding: 0;
    background: linear-gradient(to right, #3a6073, #16222a); /* Background gradient */
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-content {
    flex: 1;
    overflow-y: auto; /* Enable scrolling within the main content */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    display: flex;
    flex-direction: column;
    padding-bottom: 120px; /* Further increased padding to ensure the last card is fully visible */
    box-sizing: border-box; /* Include padding in height calculation */
}

h1 {
    font-size: 2.5rem;
    text-align: center;
    color: #1a5f7a;
    margin: 15px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #ffd700;
}

.btn {
    background-color: #003366;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border-radius: 6px;
    width: calc(50% - 1rem); /* Ensures two buttons fit nicely in a row */
    font-size: 1rem; /* Consistent font size */
}

.btn:hover {
    color: gold; /* Change color on hover */
    background-color: #004080; /* Slightly lighter blue on hover */
}

.scroll-container {
    flex-grow: 1;
    overflow-y: scroll;
    width: 100%;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

@media (max-width: 575px) {
    .scroll-container {
        max-width: 100dvw;
    }
}

